<template>
  <v-container fluid>
    <v-row class="justify-center spacer">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="10"
        xl="8"
      >
        <v-row class="justify-center">
          <v-col cols="12">
            <h2>Gallery</h2>
          </v-col>
          <v-col
            cols="12"
            v-for="(item, rowIndex) in images"
            :item="item"
            :key="rowIndex"
          >
            <div class="imgName text-center">{{ item.name }}</div>

            <v-row class="justify-center pb-4">
              <v-col
                cols="6"
                sm="4"
                md="3"
                lg="3"
                xl="3"
                v-for="(item, index) in item.links"
                :item="item"
                :key="index"
              >
                <v-card
                  v-ripple="{ center: true }"
                  elevation="8"
                  @click="dialog = true; openImage(rowIndex, index);"
                >
                  <picture alt="Painting/Illustration by Mari">
                    <v-img
                      :aspect-ratio="1"
                      :src="item.path"
                      lazy-src="/images/gallery/placeholder.png"
                      alt="Painting/Illustration by Mari"
                    ><template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="accent"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </picture>
                </v-card>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-col>

      <!-- Pop out on mobile/tablet -->
      <v-dialog
        v-if="$vuetify.breakpoint.mobile"
        v-model="dialog"
        fullscreen
      >
        <v-card color="primary">
          <v-img
            height="100vh"
            width="100vw"
            contain
            alt="Painting/Illustration by Mari"
            :src="images[activeRow].links[activeImage].path"
          >
            <v-row
              class="fill-height"
              align="center"
              no-gutters
            >
              <v-btn
                color="primary"
                fixed
                top
                right
                fab
                depressed
                small
                @click.stop="dialog = false"
              >
                <v-icon color="tertiary">mdi-close</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                fixed
                left
                fab
                depressed
                small
                @click="previousPhoto()"
              >
                <v-icon
                  large
                  color="tertiary"
                >mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                fixed
                right
                fab
                depressed
                small
                @click="nextPhoto()"
              >
                <v-icon
                  large
                  color="tertiary"
                >mdi-chevron-right</v-icon>
              </v-btn>

            </v-row>
          </v-img>
        </v-card>
      </v-dialog>

      <!-- Pop out on desktop -->
      <v-dialog
        v-else
        v-model="dialog"
        height="80vh"
        width="80vw"
      >
        <v-card color="primary">
          <v-img
            contain
            height="80vh"
            alt="Painting/Illustration by Mari"
            :src="images[activeRow].links[activeImage].path"
          >
            <v-row
              class="fill-height"
              align="center"
              no-gutters
            >
              <v-btn
                class="mt-8"
                color="primary"
                absolute
                top
                right
                fab
                depressed
                small
                @click.stop="dialog = false"
              >
                <v-icon color="tertiary">mdi-close</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                absolute
                left
                fab
                depressed
                small
                @click="previousPhoto()"
              >
                <v-icon
                  large
                  color="tertiary"
                >mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                absolute
                right
                fab
                depressed
                small
                @click="nextPhoto()"
              >
                <v-icon
                  large
                  color="tertiary"
                >mdi-chevron-right</v-icon>
              </v-btn>

            </v-row>
          </v-img>
        </v-card>
      </v-dialog>

    </v-row>
  </v-container>
</template>

<style scoped>
h2 {
  font-size: 6rem;
  font-weight: 600;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin: auto;
  text-transform: uppercase;
  color: var(--v-primary);
}

@media screen and (max-width: 500px) {
  .galleryTitle {
    font-size: 4em;
  }
}

.imgName {
  text-transform: uppercase;
  padding: 10px;
  margin-top: 20px;
  line-height: 2;
}
</style>

<script>
export default {
  methods: {

    openImage(rowIndex, index) {
      this.activeImage = index;
      this.activeRow = rowIndex;
    },

    nextPhoto() {
      this.activeImage = (this.activeImage + 1 < this.images[this.activeRow].links.length ? this.activeImage + 1 : 0)
    },

    previousPhoto() {
      this.activeImage = (this.activeImage - 1 >= 0 ? this.activeImage - 1 : this.images[this.activeRow].links.length - 1)
    },
  },

  data() {
    return {
      activeImage: 0,
      activeRow: 0,

      dialog: false,

      images: [
        {
          name: '17.',
          links:
            [
              { path: '/images/gallery/17/image1.jpeg' },
            ]
        },
        {
          name: 'Påfugl',
          links:
            [
              { path: '/images/gallery/pafugl/image1.jpeg' },
            ]
        },
        {
          name: 'Maiborn',
          links:
            [
              { path: '/images/gallery/maibarn/image1.jpeg' },
              { path: '/images/gallery/maibarn/image2.jpeg' },
              { path: '/images/gallery/maibarn/image3.jpeg' },
              { path: '/images/gallery/maibarn/image4.jpeg' },
              { path: '/images/gallery/maibarn/image5.jpeg' },
              { path: '/images/gallery/maibarn/image6.jpeg' },
              { path: '/images/gallery/maibarn/image7.jpeg' },
              { path: '/images/gallery/maibarn/image8.jpeg' },
            ]
        },
        {
          name: "VW Boble // Single line",
          links: [
            { path: '/images/gallery/car_singleline/image1.jpeg' },
            { path: '/images/gallery/car_singleline/image2.jpeg' },
            { path: '/images/gallery/car_singleline/image3.jpeg' },
            { path: '/images/gallery/car_singleline/image4.jpeg' },
            { path: '/images/gallery/car_singleline/image5.jpeg' },
          ]
        },

        {
          name: "Skater // Single line",
          links: [
            { path: '/images/gallery/skater_singleline/image1.jpeg' },
          ]
        },

        {
          name: "Jenta med dukken",
          links: [
            { path: '/images/gallery/dollgirl/image1.jpeg' },
            { path: '/images/gallery/dollgirl/image2.jpeg' },
            { path: '/images/gallery/dollgirl/image3.jpeg' },
            { path: '/images/gallery/dollgirl/image4.jpeg' },
            { path: '/images/gallery/dollgirl/image5.jpeg' },
            { path: '/images/gallery/dollgirl/image6.jpeg' },
            { path: '/images/gallery/dollgirl/image7.jpeg' },
            { path: '/images/gallery/dollgirl/image8.jpeg' },

          ]
        },

        {
          name: 'Biker',
          links: [
            { path: '/images/gallery/biker/image1.jpeg' },
            { path: '/images/gallery/biker/image2.jpeg' },
            { path: '/images/gallery/biker/image3.jpeg' },
            { path: '/images/gallery/biker/image4.jpg' },
            { path: '/images/gallery/biker/image5.jpg' },
            { path: '/images/gallery/biker/image6.jpeg' },
            { path: '/images/gallery/biker/image7.jpeg' },
            { path: '/images/gallery/biker/image8.jpeg' },
          ]
        },

        {
          name: "Mors beskyttelse",
          links: [
            { path: '/images/gallery/mors_beskyttelse/image1.jpg' },
            { path: '/images/gallery/mors_beskyttelse/image2.jpg' },
            { path: '/images/gallery/mors_beskyttelse/image3.jpg' },
            { path: '/images/gallery/mors_beskyttelse/image4.jpg' },
          ]
        },

        {
          name: '9',
          links: [
            { path: '/images/gallery/ni/image1.jpeg' },
            { path: '/images/gallery/ni/image2.jpeg' },
            { path: '/images/gallery/ni/image3.jpeg' },
            { path: '/images/gallery/ni/image4.jpeg' },
          ]
        },

        {
          name: "Legogutt",
          links: [
            { path: '/images/gallery/legoboy/image1.jpg' },
            { path: '/images/gallery/legoboy/image2.jpg' },
            { path: '/images/gallery/legoboy/image3.jpg' },
          ]
        },

        {
          name: "Utskudd",
          links: [
            { path: '/images/gallery/utskudd/image1.jpg' },
            { path: '/images/gallery/utskudd/image2.jpg' },
            { path: '/images/gallery/utskudd/image3.jpg' },
            { path: '/images/gallery/utskudd/image4.jpg' },

          ]
        },

        {
          name: 'Flytende masse // Abstract',
          links: [
            { path: '/images/gallery/flytende_masse/image1.jpeg' },
            { path: '/images/gallery/flytende_masse/image2.jpeg' },
            { path: '/images/gallery/flytende_masse/image3.jpeg' },
            { path: '/images/gallery/flytende_masse/image4.jpeg' },
            { path: '/images/gallery/flytende_masse/image5.jpeg' },
            { path: '/images/gallery/flytende_masse/image6.jpeg' },
            { path: '/images/gallery/flytende_masse/image7.jpeg' },
            { path: '/images/gallery/flytende_masse/image8.jpeg' },
          ]
        },

        {
          name: "MC",
          links: [
            { path: '/images/gallery/mc/image1.jpg' },
            { path: '/images/gallery/mc/image2.jpg' },
          ]
        },

        {
          name: 'Cocktail',
          links: [
            { path: '/images/gallery/cocktail/image1.jpeg' },
            { path: '/images/gallery/cocktail/image2.jpeg' },
          ]
        },

        {
          name: 'Hugin og Munin',
          links: [
            { path: '/images/gallery/hugin_munin/image1.jpeg' },
            { path: '/images/gallery/hugin_munin/image2.jpeg' },
          ]
        },

        {
          name: "P-flow covers",
          links: [
            { path: '/images/gallery/p_flow/image1.jpeg' },
            { path: '/images/gallery/p_flow/image2.jpeg' },
            { path: '/images/gallery/p_flow/image3.jpeg' },
          ]
        },

      ]

    };
  },
};
</script>

